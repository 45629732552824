<template>
  <section class="card" style="position:relative">
    <div class="row justify-content-lg-center card-header p-0">
      <div class="col-lg-7 ">
        <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
          <template slot="header">
            <h4>
              Criar estoque
            </h4>
          </template>
        </data-header>
      </div>
      
    </div>
    
    <div class="row h-100">
      <div class="col h-100">
        <div class="card-body">
          <div class="row justify-content-lg-center">
            <div class="col-lg-7">
              <div class="validation-message" v-if="$root.errors.length">
            <ul style="display: block;">
              <template v-for="p in $root.errors">
                <li :key="p">
                  <label id="fullname-error" class="error" for="fullname" style="">{{ p }}</label>

                </li>
              </template>

            </ul>
          </div>
          <div>
            <h4 class="text-primary">Estoque</h4>
            <div class="row form-group ml-0 mr-0">
              <div class="col-lg-2">
                <div class="form-group">
                  <div class="form-group">
                    <label class="col-form-label">Data compra</label><span class="text-danger"> *</span>
                    <input type="date" :max="today" class="form-control form-control-sm" v-model="obj.DataCompra" />
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <div class="form-group">
                    <label class="col-form-label">Valor compra</label><span class="text-danger"> *</span>
                    <money v-model="obj.ValorCompra"
                           class="form-control form-control-sm"
                           id="ValorCompra"
                           name="ValorCompra" />
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="col-form-label">Tipo estoque</label>
                  <select v-model="obj.TipoEstoque"
                          class="form-control form-control-sm">

                    <option value="0">
                      Seminovo
                    </option>
                    <option value="1">
                      Novo
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <div class="form-group">
                    <label class="col-form-label">Cód. Integração</label>
                    <input type="text" class="form-control form-control-sm" v-model="obj.IdIntegracao" />
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="col-form-label">Status</label>
                  <select v-model="obj.StatusEstoque"
                          class="form-control form-control-sm"
                          @change="handleChangeStatus">

                    <option value="1">
                      Em preparo
                    </option>
                    <option value="2">
                      Estoque
                    </option>
                    <option value="3">
                      Vendido
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <div class="form-group">
                    <label class="col-form-label">Data pronto</label>
                    <input type="date" :max="today" class="form-control form-control-sm" v-model="obj.DataPronto" :disabled="obj.StatusEstoque == 1" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row form-group ml-0 mr-0">
              <div class="col-lg-2">
                <div class="form-group">
                  <div class="form-group">
                    <label class="col-form-label">Data venda</label>
                    <input type="date" :max="today" class="form-control form-control-sm" v-model="obj.DataVenda" :disabled="obj.StatusEstoque != 3" />
                  </div>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="form-group">
                  <div class="form-group">
                    <label class="col-form-label">Valor venda</label>
                    <money v-model="obj.ValorVenda"
                           class="form-control form-control-sm"
                           :disabled="obj.StatusEstoque != 3" />
                  </div>
                </div>
              </div>


            </div>
          </div>

          <veiculo-create-partial ref="veiculo" :obj="obj" :attrsVeiculo="attrsVeiculo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import veiculoPartial from '@/components/VeiculoPartial.vue';
  import { veiculoEmpty } from '@/models/index.js';
  import api from '@/services/api.js';
  import consultar from '@/services/consultar.js';
  import { prepararVeiculo } from '@/services/helpers.js';

  export default {
    name: 'VeiculosCrud',
        data() {
      const that = this;

      return {
        ...consultar.data(),
        attrsVeiculo: { opcionais: [], cor: [], combustivel: [], transmissao: [], carroceria: [] },
        url: '/configuracoes/preferencias',
        clone: '',
        today: '',
        obj: {
          DataCompra: '',
          ValorCompra: '',
          TipoEstoque: 0,
          StatusEstoque: 1,
          DataPronto: '',
          DataVenda: '',
          ValorVenda: '',
          Opcional: [],

          Veiculo: veiculoEmpty()
        },
        actionButtons: [
            {
                text: 'Salvar',
                className: 'salvar padrao',
                icon: 'fas fa-save',
                color: 'primary',
                visible: true,
                title: 'Salvar',
                callback: that.handleSalvarButton
            },
            {
                text: 'Cancelar',
                className: 'btn-cancelar padrao',
                icon: '',
                iconColor: '',
                color: 'danger',
                visible: true,
                title: 'Cancelar',
              callback: function () {
                that.$router.push('/');
              }
            },
        ],
        filtros:
        {
            visaoSelecionada: 0,
            visible: true,
            data: []
        },
            
            
        itemSelecionado: [0]

      }
    },

    components: {
        ...consultar.components,
      'veiculo-create-partial': veiculoPartial
    },
    methods: {
      ...consultar.methods,
      handleChangeStatus($event) {
        let value = $event.target.value;
        let date = new Date().toISOString();

        switch (value) {
          case '1':
            this.obj.DataPronto = '';
            this.obj.DataVenda = '';
            this.obj.ValorVenda = '';

            break;
          case '2':
            this.obj.DataPronto = date.substr(0, date.indexOf('T'));
            this.obj.DataVenda = '';
            this.obj.ValorVenda = '';
            break;
          case '3':
            date = date.substr(0, date.indexOf('T'));
            this.obj.DataPronto = date;
            this.obj.DataVenda = date;
            break;
          default:
        }
      },
      handleSalvarButton() {
        //validacao estoque
        let that = this;
        this.$root.errors = [];

        if (!this.obj.DataCompra) {
          this.$root.errors.push('Campo "data compra" inválido');
        }
        if (this.obj.ValorCompra === 0) {
          this.$root.errors.push('Campo "valor compra" inválido');
        }

        switch (this.obj.StatusEstoque) {
          case '2':
            if (!this.obj.DataPronto) {
              this.$root.errors.push('Campo "data pronto" inválido')
            }
            break;
          case '3':
            if (!this.obj.DataPronto) {
              this.$root.errors.push('Campo "data pronto" inválido')
            }
            if (!this.obj.DataVenda) {
              this.$root.errors.push('Campo "data venda" inválido')
            }
            if (this.obj.ValorVenda === 0) {
              this.$root.errors.push('Campo "valor venda" inválido')
            }

            break;
        }
        if (!this.$root.isValid()) {
          return;
        }

        //validação veiculo
        if (!this.$root.validarVeiculo(this.obj.Veiculo)) {
          return;
        }

        prepararVeiculo(this.obj.Veiculo);

        this.obj.Veiculo.Opcional = this.obj.Opcional;

        //salvar
        api
          .post('/veiculos/veiculos', this.obj)
          .then(response => {
              if (response.data.success) {
                  that.$router.push('/');
              }
              else {
                  that.$root.errors = response.data.errors;
              }
          })

      },
    },
    beforeMount: function () {

      let that = this;
      let date = new Date().toISOString();
      date = date.substr(0, date.indexOf('T'));

      this.today = date;

      api.get('/avaliacoes/create').then(function (response) {
        let { opcionais, cor, combustivel, transmissao, carroceria } = response.data;
        
        that.attrsVeiculo.opcionais = opcionais;
        that.attrsVeiculo.cor = cor;
        that.attrsVeiculo.combustivel = combustivel;
        that.attrsVeiculo.transmissao = transmissao;
        that.attrsVeiculo.carroceria = carroceria;
      });

    },
    mounted: function () {
      let that = this;
      let date = new Date().toISOString();
      date = date.substr(0, date.indexOf('T'));

      this.$nextTick(() => {
        if (that.$route.params.id) {
          api.get('/veiculos/editar/' + that.$route.params.id)
            .then(response => {
              if (response.data) {
                that.$refs.veiculo.pushMarca(response.data.Veiculo.Modelo.Marca);
                that.$refs.veiculo.pushModelo(response.data.Veiculo.Modelo);
                that.$refs.veiculo.pushAno(response.data.Veiculo);
                
                this.$nextTick(() => {
                  that.obj = response.data;

                  date = that.obj.DataCompra;
                  that.obj.DataCompra = date ? date.substr(0, date.indexOf('T')) : '';

                  date = that.obj.DataPronto;
                  that.obj.DataPronto = date ? date.substr(0, date.indexOf('T')) : '';

                  date = that.obj.DataVenda;
                  that.obj.DataVenda = date ? date.substr(0, date.indexOf('T')) : '';
                });
              }
              else {
                that.$router.push('/veiculos');
              }
            });
        }
      })
    }

  };
</script>

<style scoped>
  .card{
    height: auto;
  }
</style>
